<template lang="pug">
  div.wrap-module-tmp
    div.f.fm.mb12.mt20
      h2 トレーニング設定

</template>

<style lang="scss" scoped>
.wrap-module-tmp {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  h2 {
    color: #2a2a2a;
    font-size: 16px;
    border-bottom: solid #2a2a2a 1px;
  }
  .settings-content {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
  },
  props: {
  },
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>
