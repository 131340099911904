<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header
    div.wrap-settings
      ModuleSettings

</template>

<style lang="scss" scoped>
.wrap-settings {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/auth'
import Header from '@/components/shared/Header'
import ModuleSettings from '@/components/module/ModuleSettings'

export default {
  components: {
    Auth,
    Header,
    ModuleSettings
  },
  data () {
    return {

    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)
    },
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    }
  }
}
</script>
